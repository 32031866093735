/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { DeployLatestReleaseActionResource, ProjectResource, ChannelResource, EnvironmentResource, ResourceCollection, LifecycleResource, PhaseResource } from "client/resources";
import { repository } from "clientInstance";
import { Select, ExpandableFormSection, Summary, Checkbox } from "components/form";
import LookupResourceChipComponent from "components/LookupResourceChip";
import { EnvironmentChip, ChipIcon } from "components/Chips";
import { isEqual, compact } from "lodash";

interface DeployLatestReleaseActionEditorProps {
    action: DeployLatestReleaseActionResource;
    allEnvironments: EnvironmentResource[];
    lifecycle: LifecycleResource;
    onActionChange(action: DeployLatestReleaseActionResource): void;
}

interface DeployLatestReleaseActionEditorState {
    sourceEnvironmentIds: string[];
    destinationEnvironmentId: string;
    shouldRedeployWhenReleaseIsCurrent: boolean;
}

export class DeployLatestReleaseActionEditor extends React.Component<DeployLatestReleaseActionEditorProps, DeployLatestReleaseActionEditorState> {
    sourceEnvironments: EnvironmentResource[] = [];
    destinationEnvironments: EnvironmentResource[] = [];
    resetSourceEnvironments: boolean = undefined!;
    resetDestinationEnvironments: boolean = undefined!;

    constructor(props: DeployLatestReleaseActionEditorProps) {
        super(props);

        this.state = this.initState(this.props.action);
    }

    componentWillReceiveProps(newProps: DeployLatestReleaseActionEditorProps) {
        const sourceEnvironmentsAreTheSame = isEqual(this.props.action.SourceEnvironmentIds || [], newProps.action.SourceEnvironmentIds || []);
        const destinationEnvironmentsAreTheSame = this.props.action.DestinationEnvironmentId === newProps.action.DestinationEnvironmentId;

        if (!sourceEnvironmentsAreTheSame || !destinationEnvironmentsAreTheSame) {
            this.resetSourceEnvironments = !newProps.action.DestinationEnvironmentId;
            this.resetDestinationEnvironments = !newProps.action.SourceEnvironmentIds || newProps.action.SourceEnvironmentIds.length === 0;
            this.setState(this.initState(newProps.action));
        }

        const lifecycleChanged = this.props.action.ChannelId !== newProps.action.ChannelId || this.props.lifecycle.Id !== newProps.lifecycle.Id;
        this.initEnvironments(newProps.lifecycle, newProps.allEnvironments, lifecycleChanged);
    }

    render() {
        return (
            <div>
                <ExpandableFormSection errorKey="SourceEnvironment" title="Source environment" focusOnExpandAll summary={this.buildSourceEnvironmentSummary()} help="The environment to use when selecting the release to deploy from.">
                    <Select
                        allowClear={true}
                        items={this.sourceEnvironments.map((e) => {
                            return { text: e.Name, value: e.Id };
                        })}
                        value={this.getSourceEnvironmentFromState()!}
                        onChange={this.onSourceEnvironmentChange}
                    />
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="DestinationEnvironment" title="Destination environment" focusOnExpandAll summary={this.buildDestinationEnvironmentSummary()} help="The environment to deploy the selected release to.">
                    <Select
                        allowClear={true}
                        items={this.destinationEnvironments.map((e) => {
                            return { text: e.Name, value: e.Id };
                        })}
                        value={this.state.destinationEnvironmentId}
                        onChange={this.onDestinationEnvironmentChange}
                    />
                </ExpandableFormSection>
                <ExpandableFormSection
                    errorKey="Redeploy"
                    title="Re-deploy"
                    summary={
                        this.state.shouldRedeployWhenReleaseIsCurrent
                            ? Summary.default("Re-deploy latest release to destination environment even if already up-to-date")
                            : Summary.summary("Do not re-deploy latest release to destination environment if already up-to-date")
                    }
                    help="Choose whether Octopus should re-deploy latest release to destination environment if it is already up-to-date"
                >
                    <Checkbox label="Re-deploy" value={this.state.shouldRedeployWhenReleaseIsCurrent} onChange={this.onShouldRedeployChange} />
                </ExpandableFormSection>
            </div>
        );
    }

    initState(value?: DeployLatestReleaseActionResource) {
        const action = value || new DeployLatestReleaseActionResource();
        return {
            sourceEnvironmentIds: action.SourceEnvironmentIds,
            destinationEnvironmentId: action.DestinationEnvironmentId,
            shouldRedeployWhenReleaseIsCurrent: action.ShouldRedeployWhenReleaseIsCurrent,
        };
    }

    private environmentChip = (id: string) => {
        const LookupEnvironmentChip = LookupResourceChipComponent<EnvironmentResource>();

        return <LookupEnvironmentChip lookupCollection={this.props.allEnvironments} key={id} lookupId={id} type={ChipIcon.Environment} chipRender={(item) => <EnvironmentChip environmentName={item.Name} />} />;
    };

    private buildSourceEnvironmentSummary = () => {
        const envs = this.state.sourceEnvironmentIds || [];
        return envs.length > 0
            ? Summary.summary(
                  <span>
                      Latest release in the {envs.map((e) => this.environmentChip(e))} environment{envs.length === 1 ? "" : "s"} will be deployed
                  </span>
              )
            : Summary.placeholder("No source environment selected");
    };

    private buildDestinationEnvironmentSummary = () => {
        return this.state.destinationEnvironmentId
            ? Summary.summary(<span>Latest release will be deployed to the {this.environmentChip(this.state.destinationEnvironmentId)} environment</span>)
            : Summary.placeholder("No destination environment selected");
    };

    private onSourceEnvironmentChange = (environmentId: string | undefined) => {
        this.setState(
            {
                sourceEnvironmentIds: [environmentId!],
            },
            () => this.raiseChange()
        );
    };

    private onDestinationEnvironmentChange = (environmentId: string | undefined) => {
        this.setState(
            {
                destinationEnvironmentId: environmentId!,
            },
            () => this.raiseChange()
        );
    };

    private onShouldRedeployChange = (shouldRedeployWhenMachineHasBeenDeployedTo: boolean) => {
        this.setState(
            {
                shouldRedeployWhenReleaseIsCurrent: shouldRedeployWhenMachineHasBeenDeployedTo,
            },
            () => this.raiseChange()
        );
    };

    private raiseChange = () => {
        this.props.onActionChange({
            ...this.props.action,
            SourceEnvironmentIds: this.state.sourceEnvironmentIds,
            DestinationEnvironmentId: this.state.destinationEnvironmentId,
            ShouldRedeployWhenReleaseIsCurrent: this.state.shouldRedeployWhenReleaseIsCurrent,
        });
    };

    private initEnvironments(lifecycle: LifecycleResource, allEnvironments: EnvironmentResource[], lifecycleChanged: boolean) {
        let sourceEnvironmentId = this.getSourceEnvironmentFromState();
        if (lifecycleChanged || (!sourceEnvironmentId && !this.state.destinationEnvironmentId)) {
            this.sourceEnvironments = this.destinationEnvironments = this.getAllEnvironments(lifecycle, allEnvironments)!;

            if (lifecycleChanged) {
                let destinationEnvironmentId = this.state.destinationEnvironmentId;
                let shouldRaiseChange = false;
                if (!this.sourceEnvironments.some((e) => e.Id === sourceEnvironmentId)) {
                    sourceEnvironmentId = null;
                    shouldRaiseChange = true;
                }
                if (!this.destinationEnvironments.some((e) => e.Id === destinationEnvironmentId)) {
                    destinationEnvironmentId = null!;
                    shouldRaiseChange = true;
                }
                if (shouldRaiseChange) {
                    this.setState(
                        {
                            sourceEnvironmentIds: [sourceEnvironmentId!],
                            destinationEnvironmentId,
                        },
                        () => this.raiseChange()
                    );
                    return;
                }
            }
        }

        if (sourceEnvironmentId) {
            if (this.resetSourceEnvironments) {
                this.sourceEnvironments = this.getAllEnvironments(lifecycle, allEnvironments);
            }

            const currentPhase = lifecycle.Phases.find((p) => p.AutomaticDeploymentTargets.includes(sourceEnvironmentId!) || p.OptionalDeploymentTargets.includes(sourceEnvironmentId!));
            const phaseIndex = lifecycle.Phases.indexOf(currentPhase!);

            if (!currentPhase) {
                // if there's no currentPhase it means the user cannot see an environment that matches the current `destinationEnvironmentId`
                this.destinationEnvironments = this.getAllEnvironments(lifecycle, allEnvironments);
                return;
            }

            const canGetNextPhase = (index: number) => index < lifecycle.Phases.length - 1;
            const getNextPhaseIndex = (index: number) => index + 1;
            const nextEnvironments = this.getEnvironments(lifecycle.Phases, phaseIndex, canGetNextPhase, getNextPhaseIndex);

            this.destinationEnvironments = this.filterToAccessibleEnvironments(currentPhase.AutomaticDeploymentTargets.concat(currentPhase.OptionalDeploymentTargets, nextEnvironments), allEnvironments);
        }

        if (this.state.destinationEnvironmentId) {
            if (this.resetDestinationEnvironments) {
                this.sourceEnvironments = this.getAllEnvironments(lifecycle, allEnvironments);
            }

            const currentPhase = lifecycle.Phases.find((p) => p.AutomaticDeploymentTargets.includes(this.state.destinationEnvironmentId) || p.OptionalDeploymentTargets.includes(this.state.destinationEnvironmentId));

            if (!currentPhase) {
                // if there's no currentPhase it means the user cannot see an environment that matches the current `destinationEnvironmentId`
                this.sourceEnvironments = this.getAllEnvironments(lifecycle, allEnvironments);
                return;
            }

            const phaseIndex = lifecycle.Phases.indexOf(currentPhase);

            const canGetPreviousPhase = (index: number) => index > 0;
            const getPreviousPhaseIndex = (index: number) => index - 1;
            const previousEnvironments = this.getEnvironments(lifecycle.Phases, phaseIndex, canGetPreviousPhase, getPreviousPhaseIndex);

            this.sourceEnvironments = this.filterToAccessibleEnvironments(previousEnvironments.concat(currentPhase.AutomaticDeploymentTargets, currentPhase.OptionalDeploymentTargets), allEnvironments);
        }
    }

    private getSourceEnvironmentFromState() {
        return this.state.sourceEnvironmentIds ? this.state.sourceEnvironmentIds[0] : null;
    }

    private getAllEnvironments = (lifecycle: LifecycleResource, allEnvironments: EnvironmentResource[]) => {
        return this.filterToAccessibleEnvironments(
            lifecycle.Phases.map((p) => p.AutomaticDeploymentTargets.concat(p.OptionalDeploymentTargets)).reduce((environmentIds, environmentId) => environmentIds.concat(environmentId), []),
            allEnvironments
        );
    };

    private getEnvironments = (phases: PhaseResource[], index: number, canGetPhase: (index: number) => boolean, getPhaseIndex: (index: number) => number): string[] => {
        const phase = canGetPhase(index) ? phases[getPhaseIndex(index)] : null;
        let phaseEnvironments: string[] = [];
        if (phase) {
            if (phase.IsOptionalPhase) {
                const nextIndex = getPhaseIndex(index);
                phaseEnvironments = this.getEnvironments(phases, nextIndex, canGetPhase, getPhaseIndex).concat([]);
            }
            phaseEnvironments = phaseEnvironments.concat(phase.AutomaticDeploymentTargets, phase.OptionalDeploymentTargets);
        }
        return phaseEnvironments;
    };

    private filterToAccessibleEnvironments = (environmentIds: string[], allEnvironments: EnvironmentResource[]) => {
        return compact(environmentIds.map((envId: string) => allEnvironments.find((e) => e.Id === envId)).filter((e) => e));
    };
}

/* eslint-disable no-restricted-imports */
import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "../../theme";
import * as React from "react";
import { OctopusTheme, useOctopusTheme } from "../Theme";
import Avatar, { AvatarClassKey } from "@material-ui/core/Avatar";
import styles from "./IconStyledForChips.less";
import { PickClassNameKeys } from "../../primitiveComponents/types/PickClassNameKeys";
import { CSSProperties } from "react";

const useAvatarStyles = makeStyles<Theme, { backgroundColor?: string; octopusTheme: OctopusTheme }, PickClassNameKeys<AvatarClassKey, "root">>({
    root: ({ octopusTheme, backgroundColor }) => ({
        width: 24,
        height: 24,
        backgroundColor: backgroundColor ?? octopusTheme.avatarBackground,
    }),
});

interface IconStyledForChipProps {
    iconComponent: React.ComponentType<{ className?: string; style?: CSSProperties }>;
    backgroundColor?: string;
    iconColor?: string;
}

export function IconStyledForChip(props: IconStyledForChipProps): React.ReactElement | null {
    const { iconComponent: Icon } = props;
    const octopusTheme = useOctopusTheme();
    const avatarClasses = useAvatarStyles({ backgroundColor: props.backgroundColor, octopusTheme });

    return (
        <Avatar classes={avatarClasses}>
            <Icon className={styles.icon} style={{ fill: props.iconColor ?? octopusTheme.chipIcon }} />
        </Avatar>
    );
}

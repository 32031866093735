/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable no-eq-null */

import { Permission, ProjectResource, ProcessType } from "client/resources";
import routeLinks from "routeLinks";
import React from "react";
import Onboarding from "./Common/Onboarding";
import SidebarLayout from "components/SidebarLayout/SidebarLayout";
import SideBar from "./Common/SideBar";
import { PermissionCheck } from "components/PermissionCheck";
import { Checkbox, Select, UnstructuredFormSection } from "components/form";
import FilterSearchBox from "components/FilterSearchBox";
import { NoResults } from "components/Images/NoResults/NoResults";
import { useProjectContext } from "areas/projects/context";
import { Errors } from "components/DataBaseComponent";
import AdvancedFilterLayout from "components/AdvancedFilterLayout/AdvancedFilterLayout";
import { ProcessSearchFilter } from "./ListItems/ProcessListItemContextMenu";
import { flatten } from "lodash";
import InternalLink from "components/Navigation/InternalLink";
import Callout, { CalloutType } from "primitiveComponents/dataDisplay/Callout";
import Logger from "client/logger";
import ProcessListPageListItemForAction from "./ListItems/ProcessListPageListItemForAction";
import ProcessListPageListItemForParentStep from "./ListItems/ProcessListPageListItemForParentStep";
import { ProcessEditorSearchFilterNamedResource } from "./Contexts/ProcessSearchFilter";
import { ProcessContextProps, useProcessContext } from "./Contexts/ProcessContext";
import { ProcessListLayoutLoaderLookupData } from "./ProcessListLayoutLoader";
import { RunbookProcessOverviewSidebar } from "../Runbooks/RunbookProcessOverviewSidebar";
import RunbookOnboarding from "../Runbooks/RunbookOnboarding";
import { useProcessSearchFilterContext } from "./Contexts/ProcessSearchFilter/ProcessSearchFilterContext";
import { useActionTemplatesFromContext } from "./Contexts/ProcessActionTemplatesContextProvider";

const processListItemStyles = require("./ListItems/ProcessListItem.less");

class FilterLayout extends AdvancedFilterLayout<ProcessSearchFilter> {}

export interface ProcessListProps {
    lookups: ProcessListLayoutLoaderLookupData;
    busy?: boolean;
    errors?: Errors;
}

const ProcessList: React.FC<ProcessListProps> = ({ lookups, busy, errors }) => {
    const processContext = useProcessContext();
    const processType = processContext.selectors.getProcessType();
    const projectContext = useProjectContext();
    const processSearchFilterContext = useProcessSearchFilterContext();
    const project = projectContext.state.model;
    const actionTemplates = useActionTemplatesFromContext();

    const { state: processContextState } = processContext;
    const { state: processSearchFilterContextLookupsState, actions: processSearchFilterContextActions } = processSearchFilterContext;
    const channelsById = lookups.channelsByNameOrId;

    const hasValidProcess: boolean = processContext.selectors.hasValidProcess();
    return (
        <>
            {hasValidProcess && processContextState.model.steps.allIds.length === 0 && (processType === ProcessType.Deployment ? <Onboarding /> : <RunbookOnboarding />)}
            {hasValidProcess && processContextState.model.steps.allIds.length > 0 && (
                <>
                    <SidebarLayout
                        sideBar={
                            processType === ProcessType.Deployment ? (
                                <SideBar
                                    process={processContextState.model.process}
                                    includedScriptModules={lookups.includedScriptModules}
                                    lifecyclePreview={lookups.lifecyclePreview}
                                    environments={Object.values(lookups.environmentsByNameOrId || {})}
                                    onDataChanged={() => processContext.actions.refreshLookupData()}
                                />
                            ) : (
                                <RunbookProcessOverviewSidebar projectId={projectContext.state.model.Id} onDataChanged={() => processContext.actions.refreshLookupData()} includedScriptModules={lookups.includedScriptModules} />
                            )
                        }
                        hideTopDivider={processType === ProcessType.Runbook} // Our tabs already provide a dividing line in runbooks.
                    >
                        <FilterLayout
                            filter={processSearchFilterContextLookupsState.searchFilter}
                            filterSections={[
                                {
                                    render: (
                                        <>
                                            <PermissionCheck permission={Permission.EnvironmentView} wildcard={true}>
                                                <Select
                                                    value={processSearchFilterContextLookupsState.searchFilter.environment?.Id}
                                                    onChange={(environmentId) => {
                                                        let environment: ProcessEditorSearchFilterNamedResource;
                                                        if (environmentId) {
                                                            const resource = lookups.environmentsByNameOrId[environmentId];
                                                            environment = {
                                                                Id: resource.Id,
                                                                Name: resource.Name,
                                                            };
                                                        }
                                                        processSearchFilterContextActions.onFilterChange((t) => ({ ...t, environment }));
                                                    }}
                                                    items={Object.values(lookups.environmentsByNameOrId).map((e) => ({ value: e.Id, text: e.Name }))}
                                                    allowClear={true}
                                                    allowFilter={true}
                                                    fieldName="environment"
                                                />
                                            </PermissionCheck>
                                            {processType === ProcessType.Deployment && channelsById && Object.keys(channelsById).length > 1 && (
                                                <Select
                                                    value={processSearchFilterContextLookupsState.searchFilter.channel?.Id}
                                                    onChange={(channelId) => {
                                                        let channel: ProcessEditorSearchFilterNamedResource;
                                                        if (channelId) {
                                                            const resource = channelsById[channelId];
                                                            channel = {
                                                                Id: resource.Id,
                                                                Name: resource.Name,
                                                            };
                                                        }
                                                        processSearchFilterContextActions.onFilterChange((t) => ({ ...t, channel }));
                                                    }}
                                                    items={Object.values(channelsById).map((e) => ({ value: e.Id, text: e.Name }))}
                                                    allowClear={true}
                                                    allowFilter={true}
                                                    fieldName="channel"
                                                />
                                            )}
                                            <Checkbox
                                                label="Include unscoped steps"
                                                value={!!processSearchFilterContextLookupsState.searchFilter.includeUnscoped}
                                                onChange={(includeUnscoped) => {
                                                    processSearchFilterContextActions.onFilterChange((prev) => ({ ...prev, includeUnscoped }));
                                                }}
                                            />
                                        </>
                                    ),
                                },
                            ]}
                            onFilterReset={(filter) => processSearchFilterContextActions.onFilterChange(() => filter)}
                            defaultFilter={processSearchFilterContext.getEmptyFilter()}
                            initiallyShowFilter={processSearchFilterContext.isFiltering}
                            additionalHeaderFilters={[
                                <FilterSearchBox
                                    placeholder="Filter by name..."
                                    value={processSearchFilterContextLookupsState.searchFilter.filterKeyword}
                                    onChange={(filterKeyword) => processSearchFilterContextActions.onFilterChange((prev) => ({ ...prev, filterKeyword }))}
                                    autoFocus={true}
                                />,
                            ]}
                            renderContent={() => (
                                <>
                                    {processType === ProcessType.Deployment && getInvalidConfigurationCallouts(processContext, project)}
                                    <div className={processListItemStyles.stepList}>
                                        {processSearchFilterContext.filteredSteps.steps.length > 0 ? (
                                            processSearchFilterContext.filteredSteps.steps
                                                .filter((x) => x.filtered)
                                                .map(({ step: filteredStep, index }) => {
                                                    const step = processContext.selectors.getStepById(filteredStep.Id);
                                                    if (!step) {
                                                        Logger.log(`Failed to find step with name ${filteredStep.Name}`);
                                                        return null;
                                                    }
                                                    if (step.ActionIds.length === 1) {
                                                        const action = processContext.selectors.getActionById(step.ActionIds[0]);
                                                        return <ProcessListPageListItemForAction key={action.Id} actionTemplates={actionTemplates} step={step} action={action} actionIndex={index} lookups={lookups} />;
                                                    } else {
                                                        return <ProcessListPageListItemForParentStep key={step.Id} actionTemplates={actionTemplates} step={step} stepIndex={index} lookups={lookups} errors={errors} />;
                                                    }
                                                })
                                        ) : (
                                            <NoResults />
                                        )}
                                    </div>
                                </>
                            )}
                        />
                    </SidebarLayout>
                </>
            )}
        </>
    );
};

function calculateDetailsUrl(project: ProjectResource, id: string): string {
    return routeLinks.project(project.Id).deployments.process.step(id);
}

function getInvalidAutomaticReleaseCreationConfigurationCallout(processContext: ProcessContextProps, project: ProjectResource) {
    if (project.AutoCreateRelease) {
        if (project.ReleaseCreationStrategy == null || project.ReleaseCreationStrategy.ReleaseCreationPackage == null) {
            return (
                <div>
                    This project is configured to use Automatic Release Creation, but the step is missing. Please adjust the <InternalLink to={routeLinks.project(project.Slug).triggers}>Automatic Release Creation</InternalLink> configuration.
                </div>
            );
        } else {
            const action = flatten(processContext.selectors.getAllActions()).filter((a) => a.Name === project.ReleaseCreationStrategy.ReleaseCreationPackage.DeploymentAction);
            if (action && action.length > 0 && action[0].IsDisabled) {
                return (
                    <div>
                        <span>
                            Step <InternalLink to={calculateDetailsUrl(project, action[0].Id)}>{action[0].Name}</InternalLink> is currently used for Automatic Release Creation, but it has been disabled.
                        </span>
                        <span>
                            Please re-enable the step, or adjust the <InternalLink to={routeLinks.project(project.Slug).triggers}>Automatic Release Creation</InternalLink> configuration.
                        </span>
                    </div>
                );
            }
        }
    }
    return null;
}

function getInvalidVersioningConfigurationCallout(processContext: ProcessContextProps, project: ProjectResource) {
    if (project.VersioningStrategy.DonorPackage) {
        const action = flatten(processContext.selectors.getAllActions()).filter((a) => a.Name === project.VersioningStrategy.DonorPackage?.DeploymentAction);
        if (action && action.length > 0 && action[0].IsDisabled) {
            return (
                <div>
                    Step <InternalLink to={calculateDetailsUrl(project, action[0].Id)}>{action[0].Name}</InternalLink> is currently used for release versioning, but it has been disabled.
                    <br />
                    Please re-enable the step or adjust the <InternalLink to={routeLinks.project(project.Slug).settings.root}>release versioning</InternalLink> configuration.
                </div>
            );
        }
    }
    return null;
}

function getInvalidConfigurationCallouts(processContext: ProcessContextProps, project: ProjectResource) {
    const arcCallout = getInvalidAutomaticReleaseCreationConfigurationCallout(processContext, project);
    const versioningCallout = getInvalidVersioningConfigurationCallout(processContext, project);
    if (arcCallout || versioningCallout) {
        return (
            <UnstructuredFormSection stretchContent={true}>
                <Callout type={CalloutType.Warning} title="Invalid Configuration">
                    {arcCallout}
                    {versioningCallout}
                </Callout>
            </UnstructuredFormSection>
        );
    }
}

export default ProcessList;

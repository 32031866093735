/* eslint-disable no-restricted-imports */
import { makeStyles } from "@material-ui/core";
import { OctopusTheme, useOctopusTheme } from "../Theme";
import { normal } from "../../fontWeights";
import MaterialChip, { ChipClassKey, ChipProps as MaterialChipProps } from "@material-ui/core/Chip";
import * as React from "react";
import { PickClassNameKeys } from "../../primitiveComponents/types/PickClassNameKeys";
import { Theme } from "theme";

type ChipStyleProps = {
    backgroundColor?: string;
    labelColor?: string;
    markAsRemoved?: boolean;
    borderColor?: string;
    fullWidth?: boolean;
    noMargin?: boolean;
    accessibilityRole?: string;
};

type ExposedMaterialChipProps = Pick<MaterialChipProps, "onClick" | "onDelete" | "deleteIcon" | "label" | "variant" | "tabIndex" | "icon" | "className">;

type StyledMaterialChipProps = ChipStyleProps & ExposedMaterialChipProps;

const useChipStyles = makeStyles<Theme, ChipStyleProps & { octopusTheme: OctopusTheme }, PickClassNameKeys<ChipClassKey, "root" | "label">>({
    root: ({ octopusTheme, ...props }) => ({
        lineHeight: "1.5rem",
        height: "1.5rem", // Specify height explicitly, or svg icons can push the container height out (eg. if you display
        // multiple chips in a row where some do/don't contain an avatar. E.g. environments list).
        margin: props.noMargin ? 0 : 4,
        display: "inline-flex",
        backgroundColor: props.backgroundColor || octopusTheme.chipBackground,
        verticalAlign: "middle",
        color: props.labelColor ?? octopusTheme.chipText,
        opacity: 1,
    }),
    label: ({ octopusTheme, ...props }) => ({
        lineHeight: "1.5rem",
        fontSize: "0.75rem",
        color: props.labelColor || octopusTheme.chipText,
        fontWeight: normal,
        maxWidth: props.fullWidth ? "none" : "12.5rem",
        textDecoration: props.markAsRemoved ? "line-through" : "none",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    }),
});

export function StyledMaterialChip(props: StyledMaterialChipProps) {
    const octopusTheme = useOctopusTheme();
    const classes = useChipStyles({ octopusTheme, ...props });
    const { label, icon, onClick } = props;
    //We can't just pass role through as undefined as it's a primitive aria attribute which will end up overriding anything material-ui provides
    //we therefore omit this prop when it hasn't been specified to allow the role to be changed if needed.
    const ariaProps = props.accessibilityRole ? { role: props.accessibilityRole } : {};

    return <MaterialChip classes={classes} icon={props.icon} label={props.label} onClick={props.onClick} tabIndex={props.tabIndex} variant={props.variant} onDelete={props.onDelete} deleteIcon={props.deleteIcon} {...ariaProps} />;
}

export default StyledMaterialChip;

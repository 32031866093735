/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { EnvironmentResource, LifecycleResource } from "client/resources";
import { BooleanRadioButtonGroup, RadioButton, Select } from "components/form";
import { Callout, CalloutType } from "primitiveComponents/dataDisplay/Callout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import OkDialogLayout from "components/DialogLayout/OkDialogLayout";

interface AddEnvironmentProps {
    environmentsById: Partial<Record<string, EnvironmentResource>>;
    lifecycle: LifecycleResource;
    automatic?: boolean;
    selectedEnvironment?: EnvironmentResource;

    onOk(selectedEnvrionmentId: string, automatic: boolean): void;
}

interface AddEnvironmentState extends DataBaseComponentState {
    automatic: boolean;
    selectedEnvironmentId: string;
    environments: EnvironmentResource[];
}

export default class AddEnvironment extends DataBaseComponent<AddEnvironmentProps, AddEnvironmentState> {
    constructor(props: AddEnvironmentProps) {
        super(props);

        const environmentsInUse: Record<string, boolean> = {};
        this.props.lifecycle.Phases.forEach((p) => {
            p.AutomaticDeploymentTargets.forEach((e) => {
                environmentsInUse[e] = true;
            });
            p.OptionalDeploymentTargets.forEach((e) => {
                environmentsInUse[e] = true;
            });
        });

        const candidateEnvironments: EnvironmentResource[] = [];
        Object.keys(this.props.environmentsById).forEach((e) => {
            if (!environmentsInUse[e]) {
                candidateEnvironments.push(this.props.environmentsById[e]!);
            }
        });

        this.state = {
            automatic: this.props.automatic || false,
            selectedEnvironmentId: this.props.selectedEnvironment ? this.props.selectedEnvironment.Id : null!,
            environments: candidateEnvironments,
        };
    }

    onOk() {
        return this.props.onOk(this.state.selectedEnvironmentId, this.state.automatic);
    }

    render() {
        return (
            <OkDialogLayout title="Add Environment" busy={this.state.busy} errors={this.errors} okButtonDisabled={!this.state.selectedEnvironmentId} onOkClick={() => this.onOk()}>
                {this.state.environments.length === 0 && (
                    <Callout title="Important" type={CalloutType.Warning}>
                        All possible environments have already been defined in this lifecycle.
                    </Callout>
                )}

                <Select
                    label="Environment"
                    autoFocus={true}
                    allowFilter={true}
                    value={this.state.selectedEnvironmentId}
                    onChange={(val) => this.setState({ selectedEnvironmentId: val! })}
                    items={this.state.environments.map((pg) => ({ value: pg.Id, text: pg.Name }))}
                />

                <BooleanRadioButtonGroup value={this.state.automatic} onChange={(val) => this.setState({ automatic: val })}>
                    <RadioButton
                        value={true}
                        label={
                            <span>
                                Deploy <strong>automatically</strong> to this environment as soon as the release enters this phase.
                            </span>
                        }
                    />
                    <RadioButton
                        value={false}
                        label={
                            <span>
                                Users will need to <strong>manually</strong> queue the deployment to this environment.
                            </span>
                        }
                        isDefault={true}
                    />
                </BooleanRadioButtonGroup>
            </OkDialogLayout>
        );
    }
}

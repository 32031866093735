import * as React from "react";
import NavigationSidebarLayout from "components/NavigationSidebarLayout/index";
import AreaTitle from "components/AreaTitle";
import BusyIndicator from "components/BusyIndicator/BusyIndicator";
import routeLinks from "routeLinks";
import BusyFromPromise from "components/BusyFromPromise/BusyFromPromise";
import ErrorPanel from "components/ErrorPanel/ErrorPanel";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { DisabledChip } from "components/Chips";
import { ProjectContextProvider, ProjectContextState } from "../../context";
import { repository } from "clientInstance";
import { ProjectGroupResource } from "client/resources";
import { connect } from "react-redux";
import { CreateReleaseButton } from "../Releases";
import { ProjectLinks } from "./ProjectLinks";
import configurationSelectors from "areas/configuration/reducers/selectors";
import { BranchSpecifier, UseDefaultBranch } from "../ProjectsRoutes/BranchSpecifier";
import { PropsWithChildren } from "react";

interface GlobalConnectedProps {
    isMultiTenancyEnabled?: boolean;
    isConfigurationAsCodeEnabled?: boolean;
}

interface ProjectLayoutProps {
    projectSlug: string;
    branchName: string | undefined;
}

type Props = ProjectLayoutProps & GlobalConnectedProps;
interface ProjectLayoutState extends DataBaseComponentState {
    projectGroups: ProjectGroupResource[] | null;
}

class ProjectLayoutInternal extends DataBaseComponent<PropsWithChildren<Props>, ProjectLayoutState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            projectGroups: null,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            this.setState({ projectGroups: await repository.ProjectGroups.all() });
        });
    }

    toBranchSpecifier(branch: string | undefined): BranchSpecifier {
        return branch ? branch : UseDefaultBranch;
    }

    render() {
        const projectSlug = this.props.projectSlug;
        const branchName = this.props.branchName;
        const branchSpecifier = this.toBranchSpecifier(branchName);
        const isMultiTenancyEnabled: boolean | undefined = this.props.isMultiTenancyEnabled;
        const isConfigurationAsCodeEnabled: boolean | undefined = this.props.isConfigurationAsCodeEnabled;

        return (
            <ProjectContextProvider doBusyTask={this.doBusyTask} projectIdOrSlug={projectSlug} branch={branchSpecifier}>
                {({ state }) => {
                    const projectLogo = state.model && state.model && state.model.Links.Logo;
                    const showBusyIndicator = !(state.model || (this.state && this.errors));
                    return state.model ? (
                        <main id="maincontent">
                            {this.areaTitle(state, false)}
                            {this.renderErrors()}
                            <NavigationSidebarLayout
                                logoUrl={projectLogo}
                                name={state.model.Name}
                                description={state.model.Description}
                                preNavbarComponent={
                                    <div>
                                        {state.model.IsDisabled && (
                                            <div>
                                                <DisabledChip />
                                            </div>
                                        )}
                                        <CreateReleaseButton projectId={state.model.Id} projectSlug={state.model.Slug} />
                                    </div>
                                }
                                navLinks={ProjectLinks(projectSlug, branchSpecifier, state.model.Id, state.summary, isMultiTenancyEnabled, isConfigurationAsCodeEnabled)}
                                content={state.model.IsVersionControlled && !state.branch ? <></> : this.props.children}
                            />
                        </main>
                    ) : (
                        <>
                            <AreaTitle link={routeLinks.projects.root} title="Projects" busyIndicator={this.renderBusy(showBusyIndicator)} />
                            {this.renderErrors()}
                        </>
                    );
                }}
            </ProjectContextProvider>
        );
    }

    renderErrors() {
        const errors = this.state && this.errors;
        if (!errors) {
            return null;
        }
        return <ErrorPanel message={errors.message} errors={errors.errors} parsedHelpLinks={errors.parsedHelpLinks} helpText={errors.helpText} helpLink={errors.helpLink} />;
    }

    private renderBusy(forceBusy: boolean) {
        return <BusyFromPromise promise={this.state.busy || forceBusy}>{(busy: boolean) => <BusyIndicator show={busy || forceBusy} />}</BusyFromPromise>;
    }

    private areaTitle(state: ProjectContextState, forceBusy: boolean) {
        const hasAccessibleProjectGroup = this.state.projectGroups && state.model.ProjectGroupId && this.state.projectGroups.find((pg) => pg.Id === state.model.ProjectGroupId);
        return !hasAccessibleProjectGroup ? (
            <AreaTitle link={routeLinks.projects.root} title="Projects" busyIndicator={this.renderBusy(forceBusy)} />
        ) : (
            <AreaTitle breadcrumbTitle="Projects" breadcrumbPath={routeLinks.projects.root} link={routeLinks.projects.filteredByGroup(state.model.ProjectGroupId)} title={hasAccessibleProjectGroup.Name} busyIndicator={this.renderBusy(forceBusy)} />
        );
    }
}

const mapGlobalStateToProps = (state: GlobalState): GlobalConnectedProps => {
    return {
        isMultiTenancyEnabled: state.configurationArea.currentSpace.isMultiTenancyEnabled,
        isConfigurationAsCodeEnabled: configurationSelectors.createFeatureEnabledSelector((t) => t.isConfigurationAsCodeEnabled)(state),
    };
};

export default connect(mapGlobalStateToProps)(ProjectLayoutInternal);

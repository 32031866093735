import * as React from "react";
import { EnvironmentResource, RunbookEnvironmentScope } from "../../../../client/resources";
import { environmentChipList } from "../../../../components/Chips";
import Callout from "../../../../primitiveComponents/dataDisplay/Callout";
import EnvironmentMultiSelect from "../../../../components/MultiSelect/EnvironmentMultiSelect";
import ExpandableFormSection from "../../../../components/form/Sections/ExpandableFormSection";
import { RadioButtonGroup, Note, Summary, RadioButton } from "components/form";
import { CalloutType } from "primitiveComponents/dataDisplay/Callout";
import Permission from "client/resources/permission";
import { difference } from "lodash";

class RunbookEnvironmentScopeRadioButtonGroup extends RadioButtonGroup<RunbookEnvironmentScope> {}

interface RunbookEnvironmentScopeState {
    hasHiddenEnvironments: boolean;
}

interface RunbookEnvironmentScopeProps {
    environmentScope: RunbookEnvironmentScope;
    environments: EnvironmentResource[];
    inclusiveEnvironments: string[];
    onEnvironmentScopeChanged(val: RunbookEnvironmentScope): void;
    onEnvironmentsChanged(environments: string[]): void;
}

export default class RunbookEnvironmentScopeSelector extends React.Component<RunbookEnvironmentScopeProps, RunbookEnvironmentScopeState> {
    constructor(props: RunbookEnvironmentScopeProps) {
        super(props);
        const unknownEnvironments = difference(
            props.inclusiveEnvironments || [],
            props.environments.map((e) => e.Id)
        );

        this.state = {
            hasHiddenEnvironments: props.environmentScope === RunbookEnvironmentScope.Specified && unknownEnvironments.length > 0,
        };
    }

    render() {
        return (
            <ExpandableFormSection title="Environments" help="Choose which environments this runbook can be run in" summary={this.environmentSummary()} errorKey="environments">
                <RunbookEnvironmentScopeRadioButtonGroup
                    accessibleName="Runbook can be run on these environments"
                    value={this.props.environmentScope}
                    onChange={(newValue) => {
                        this.props.onEnvironmentScopeChanged(newValue);
                        this.props.onEnvironmentsChanged([]);
                    }}
                >
                    <RadioButton value={RunbookEnvironmentScope.All} label="All environments" isDefault={true} disabled={this.state.hasHiddenEnvironments} />
                    <RadioButton value={RunbookEnvironmentScope.Specified} label="Run only in specific environments" />
                    {this.props.environmentScope === RunbookEnvironmentScope.Specified && (
                        <div>
                            <EnvironmentMultiSelect accessibleName="Specified environments that the runbook can run against" items={this.props.environments} onChange={this.props.onEnvironmentsChanged} value={this.props.inclusiveEnvironments} />
                            <Note>Choose the specific environments you want to be able to run this Runbook in</Note>
                            {this.state.hasHiddenEnvironments && (
                                <Callout type={CalloutType.Information} title={"Insufficient permissions"}>
                                    The {Permission.EnvironmentView} permission is preventing the selected environments from being changed because you do not have access to an environment that is specifically marked for inclusion.
                                </Callout>
                            )}
                        </div>
                    )}
                    <RadioButton value={RunbookEnvironmentScope.FromProjectLifecycles} label="Environments from Project Lifecycles" disabled={this.state.hasHiddenEnvironments} />
                </RunbookEnvironmentScopeRadioButtonGroup>
            </ExpandableFormSection>
        );
    }

    private environmentSummary() {
        if (this.props.environmentScope === RunbookEnvironmentScope.Specified) {
            return Summary.summary(<span>This runbook can only be run in {this.getChipsForEnvironments(this.props.inclusiveEnvironments)}</span>);
        }

        if (this.props.environmentScope === RunbookEnvironmentScope.FromProjectLifecycles) {
            return Summary.default("This runbook can run in all applicable Lifecycle environments");
        }

        return Summary.default("This runbook can be run in any environment");
    }

    private getChipsForEnvironments(environments: string[]) {
        if (environments.length === 0) {
            return <em>please select environments</em>;
        }

        return environmentChipList(this.props.environments, environments);
    }
}
